@layer components {
    .title {
        & > svg {
            vertical-align: middle;
            margin-right: 0.5rem;
        }
        & > svg,
        & > span {
            vertical-align: middle;
        }
    }
}
