@layer components {
    .label {
        grid-area: label;
        font-size: 14px;
        padding-top: 0px;
        &[data-variant="popup"] {
            align-self: center;
        }

        &[data-variant="long"],
        &[data-variant="table-first-row"],
        &[data-variant="data"],
        &[data-variant="popup"] {
            color: var(--color-text-secondary);
            font-weight: 300;
        }

        &[data-variant="table"] {
            display: none;
        }
    }

    .root {
        width: 100%;
        font-size: 1rem;

        &[data-variant="long"],
        &[data-variant="data"],
        &[data-variant="popup"] {
            display: grid;
            grid-template:
                "label input" auto
                "description description" auto
                "error error" auto / 180px 1fr;
        }

        &[data-variant="long"] {
            grid-template-columns: 400px 180px;
        }
        &[data-variant="popup"] {
            grid-template-columns: 150px 1fr;
            margin-top: 0.5rem;
        }

        &[data-variant="table-first-row"] {
            margin-top: 0.5rem;
        }

        &[data-variant="table"] {
            margin-top: 0.25rem;
        }
    }

    @media (width < 700px) {
        .root {
            &[data-variant="long"] {
                grid-template:
                    "label" auto
                    "input" auto
                    "description" auto
                    "error" auto / 1fr;
            }

            &[data-variant="popup"] {
                grid-template:
                    "label" auto
                    "input" auto
                    "description" auto
                    "error" auto / 1fr;
            }
        }
    }

    .error {
        grid-area: error;

        &[data-variant="table"],
        &[data-variant="table-first-row"] {
            display: none;
        }
    }

    .description {
        grid-area: description;

        &[data-variant="table"],
        &[data-variant="table-first-row"] {
            display: none;
        }
    }
}
