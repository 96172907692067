.user-grid {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 200px 200px 320px 145px 30px;
    font-size: 14px;
}

.user-grid-header {
    grid-template-rows: 14px;
    color: var(--color-primary);
}

.user-grid > div {
    display: contents;
}

.user-grid > div > * {
    padding: 5px;
}

.user-grid > div:first-child > * {
    border-bottom: 1px solid var(--color-text-secondary);
    padding-bottom: 8px;
    margin-bottom: 8px;
    font-weight: 300;
}

@media (width > 700px) {
    /* .building-finance-grid, */
    .user-grid,
    .vote-share-grid {
        min-width: 600px;
    }
}

.user-grid-divider {
    grid-column: 1/5;
    height: 2px;
    margin-top: 0px;
    margin-bottom: 20px;
    align-items: center;
}

.user-grid-item {
    height: 25px;
    align-items: center;
}

.vote-share-grid {
    display: grid;
    grid-template-columns: 150px 150px 150px;
    grid-template-rows: 20px;
    font-size: 1rem;
}

.vote-grid-divider {
    grid-column: 1/4;
    height: 5px;
    margin-top: 0px;
    margin-bottom: 20px;
    align-items: center;
}
